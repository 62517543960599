<template>
  <OfferList
    :jobs-data="jobsData"
    :translation="translations[currentLocale]"
    :lang="currentLocale"
  />
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'

import { useFetch } from '#app'
import { defineJobPosting, useCurrentLocale, useSchemaOrg } from '#imports'

import translations from './translations.json'

const OfferList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeHtml/OfferList')
)

const url = 'https://macopedia.traffit.com/public/job_posts/published'

const currentLocale = useCurrentLocale<typeof translations>()

const filters = {
  language: {
    value: currentLocale.value !== 'pl' ? 'en' : currentLocale.value,
    comparison: '='
  }
}

const { data } = await useFetch<Array<any>>(url, {
  method: 'GET',
  headers: { 'X-Request-Filter': JSON.stringify(filters) }
})

const jobsData = ref<Array<any>>([])
const jobPostingSchema = ref<Array<any>>([])

if (Array.isArray(data.value)) {
  jobsData.value = data.value.map((job: any) => {
    const id = job?.id ?? ''
    const name = job?.advert?.name ?? ''
    const url = job?.url ?? ''
    const remote = job?.options?.remote === '1'

    const location = job?.options?.job_location
      ? JSON.parse(job?.options.job_location)
      : {}

    const country = location?.country ?? ''
    const city = location?.locality ?? ''
    const region = location?.region1 ?? ''

    return { id, name, url, remote, country, city, region }
  })

  jobPostingSchema.value = data.value.map((job: any) => {
    const location = job?.options?.job_location
      ? JSON.parse(job?.options.job_location)
      : {}

    const identifier = job?.id ?? ''
    const title = job?.advert?.name
    const description = job?.advert?.values.find(
      (value: any) => value?.field_id === 'description' && value?.value
    )?.value
    const jobLocation = {
      address: {
        addressLocality: location?.locality,
        addressRegion: location?.region1,
        postalCode: location?.postcode,
        addressCountry: location?.country
      }
    }
    const applicantLocationRequirements = location?.country
    const jobLocationType = job?.options?.remote === '1' ? 'TELECOMMUTE' : null
    const employmentType = job?.options?.job_type
    const datePosted = new Date(job?.valid_start)?.toISOString()
    const validThrough = new Date(job?.valid_end)?.toISOString()

    return {
      applicantLocationRequirements,
      identifier,
      title,
      description,
      jobLocation,
      jobLocationType,
      employmentType,
      datePosted,
      validThrough
    }
  })

  useSchemaOrg([...jobPostingSchema.value.map((job) => defineJobPosting(job))])
}
</script>
